<!-- Settings -->
<template>
    <div class="card card-custom" v-loading="loading">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Wizard Body-->
                <div class="row justify-content-center">
                    <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                        <el-form ref="form" :model="form" auto-complete="false">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                <h3 class="mb-10 font-weight-bold text-dark"><i class="flaticon2-gear settingicon" style="color:#187DE4"></i> {{title_text}}</h3>
                                <div class="row">
                                  <div class="col-xl-8">
                                <div class="row">
                                    <div class="col-xl-6">
                                      <el-form-item label="Store Name" prop="website_name">
                                        <el-input v-model="form.website_name" @input.native="capitalize" auto-complete="off" :class="form.errors.has('website_name') ? 'error required':'required'"></el-input>
                                      </el-form-item>
                                    </div>
                                    <div class="col-xl-3">
                                      <el-form-item label="Store Prefix" prop="website_prefix">
                                        <el-input v-model="form.website_prefix" auto-complete="off"  :class="form.errors.has('website_prefix') ? 'error required':'required'"></el-input>
                                      </el-form-item>
                                    </div>
                                    <div class="col-xl-3">
                                      <el-form-item label="Store Blog ID" prop="website_id">
                                        <el-input v-model="form.website_id" auto-complete="off" :class="form.errors.has('website_id') ? 'error required':'required'"></el-input>
                                      </el-form-item>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6">
                                      <el-form-item label="Store URL" prop="website_url">
                                        <el-input v-model="form.website_url" auto-complete="off"  :class="form.errors.has('website_url') ? 'error required':'required'"></el-input>
                                      </el-form-item>
                                    </div>
                                    <div class="col-xl-6">
                                      <el-form-item label="Store Endpoint" prop="website_endpoint">
                                        <el-input v-model="form.website_endpoint" auto-complete="off" :class="form.errors.has('website_endpoint') ? 'error required':'required'"></el-input>
                                      </el-form-item>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6">
                                      <el-form-item label="Consumer Key" prop="consumer_key">
                                        <el-input v-model="form.consumer_key" auto-complete="off" :class="form.errors.has('consumer_key') ? 'error required':'required'"></el-input>
                                      </el-form-item>
                                    </div>
                                    <div class="col-xl-6">
                                      <el-form-item label="Consumer Secret" prop="consumer_secret">
                                        <el-input v-model="form.consumer_secret" auto-complete="off" :class="form.errors.has('consumer_secret') ? 'error required':'required'"></el-input>
                                      </el-form-item>
                                    </div>
                                </div>
                              </div>
                              <div class="col-xl-4">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="form-group mt-6 mb-0">
                                            <div class="field text-center">
                                              <div class="preview-container">
                                                <img v-if="img_preview" :src="img_preview"/>
                                              </div>                     
                                            </div>
                                            <div class="field text-center">
                                              <div class="upload-btn-container">
                                                <a href="#" class="add-logo-btn">
                                                  <span v-if="image_btn == 0">Add Logo</span>
                                                  <span v-else>Edit Logo</span>
                                                  <input type="file" @change="onFileChange">
                                                </a>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                                 <div class="row">
                                    <div class="col-xl-12">
                                      <h4>Store Address</h4>
                                    </div>
                                 </div>

                                <div class="row">
                                    <div class="col-xl-4">
                                      <el-form-item label="Email" prop="email_address">
                                        <el-input v-model="form.email_address" auto-complete="off" :class="form.errors.has('website_name') ? '':''"></el-input>
                                      </el-form-item>
                                    </div>
                                    <div class="col-xl-4">
                                      <el-form-item label="Phone" prop="phone">
                                        <el-input v-model="form.phone" auto-complete="off" :class="form.errors.has('website_name') ? '':''"></el-input>
                                      </el-form-item>
                                    </div>
                                    <div class="col-xl-4">
                                      <el-form-item label="Fax" prop="fax">
                                        <el-input v-model="form.fax" auto-complete="off" :class="form.errors.has('website_name') ? '':''"></el-input>
                                      </el-form-item>
                                    </div>
                                </div>
                                 <div class="row">
                                    <div class="col-xl-4">
                                      <el-form-item label="Address" prop="address">
                                        <vue-google-autocomplete
                                          ref="origin1"
                                          v-model="form.address"
                                          id="address_map"
                                          classname="form-control el-input__inner"
                                          name="address"
                                          placeholder="Enter your Address"
                                          v-on:placechanged="getAddressData"
                                          country="ca"
                                          :class="form.errors.has('address') ? 'error required':'required'"
                                        >
                                        </vue-google-autocomplete>

                                        <span
                                          class="form-err"
                                          v-if="form.errors.has('address')"
                                        >
                                          Address field is required.
                                        </span>
                                        
                                      </el-form-item>
                                    </div>
                                    <div class="col-xl-4">
                                      <el-form-item label="State" prop="state_id">
                                        <el-select ref="state_id" v-model="form.state_id" @change="getCityListByStateId" filterable autocomplete="off" :class="form.errors.has('state_id') ? 'error required':'required'">
                                           <el-option v-for="(state,index) in state_list" :key="index" :label="state.state_name" :value="state.state_id">
                                           </el-option>                                      
                                        </el-select> 
                                      </el-form-item>
                                    </div>
                                    <div class="col-xl-4">
                                      <el-form-item label="City" prop="city_id">
                                        <el-select ref="city_id" v-model="form.city_id" filterable autocomplete="off" :class="form.errors.has('city_id') ? 'error required':'required'">
                                           <el-option v-for="(city,index) in city_list" :key="index" :label="city.city_name" :value="city.city_id">
                                           </el-option>                                      
                                        </el-select>
                                      </el-form-item>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-xl-4">
                                      <el-form-item label="Postcode" prop="post_code">
                                        <el-input v-model="form.post_code" auto-complete="off" :maxlength="7" :class="form.errors.has('post_code') ? 'error required':'required'" placeholder="M1S 3A7" @change="validPostal(form.post_code)"></el-input>
                                      </el-form-item>
                                    </div>
                                    <div class="col-xl-4">
                                      <el-form-item label="Country" prop="country">
                                        <el-input v-model="form.country" auto-complete="off" :class="form.errors.has('country') ? '':''"></el-input>
                                      </el-form-item>
                                    </div>
                                    <div class="col-xl-4">
                                      <el-form-item label="Status" prop="is_active">
                                        <el-select ref="is_active" v-model="form.is_active">
                                           <el-option v-for="(option,index) in options" :key="index" :label="option.text" :value="option.id">
                                           </el-option>                                      
                                        </el-select>   
                                      </el-form-item>
                                    </div>
                                 </div>
                                
                            </div>

                            <!--begin: Settings Actions -->
                            <div class="d-flex justify-content-between border-top pt-5">
                                <a @click="createWebsite" class="text-white">
                                    <div class="rounded-0 btn btn-sm btn-primary font-weight-bold text-uppercase">
                                        {{submit_btn_text}}
                                    </div>
                                </a>
                            </div>
                            <!--end: Settings Actions -->
                        </el-form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>


<style>
.settingicon{font-size:30px;}
.preview-container img{max-width:200px;}
.add-logo-btn {margin-left:40px;}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from '@/core/services/api.form.services'
import {getWebsiteDetails} from "@/api/website";
import { getCityList, getStateList, getCityListByStateId,getCityByName,getStateByPrefix } from '@/api/common';
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  name: 'add_edit_website',
  data() {
    return {
      loading:false,
      error:null,
      form : new Form({
        website_name : null,
        website_id : null,
        website_logo : null,
        website_prefix : null,
        website_url : null,
        website_endpoint : null,
        consumer_secret : null,
        consumer_key : null,
        address: null,
        city_id: null,
        state_id:null,
        post_code: null,
        country:'Canada',
        phone:null,
        fax:null,
        email_address:null,
        is_active : 1,     
      }),
      city_list:[],
      state_list:[],
      img_preview: '/ctshub/media/bg/profile-img.png',
      logo_btn_text: null,
      submit_btn_text: 'Add Store',
      title_text: 'Add Store',
      api_url: '/website/create',
      response_msg: 'Website created successfully.',
       options: [
            { id: 1, text: "Active" },
            { id: 0, text: "In Active" }
        ],
      image_btn : 0
    }
  },
  components: {
    VueGoogleAutocomplete
  },
   mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Stores", route: "/website/list" },
      { title: "Add/Edit Store" }
    ]);
  },
    methods:{
      createWebsite: function() {
        this.loading=true;
        this.form.post(this.api_url).then((response) => {
          if(response.status){
              this.$router.push({ path: '/website/list' })
              this.loading=false;
              this.$showResponse('success', this.response_msg);
          }
        }).catch(error=>{
          console.log(error);
          this.loading=false;
        })
      },
      getAddressData: function (addressData) {
      if(addressData.postal_code != null){
        this.form.post_code = addressData.postal_code;
      }else{
        this.form.post_code = '';
      }
      
      var city_name = addressData.locality;
      if (city_name != null) {
        this.getCityIdByName(city_name);
      }
      var state_prefix = addressData.administrative_area_level_1;
      if (state_prefix != null) {
        this.getStateByStatePrefix(state_prefix);
      }
    
      var street = "";
      if (addressData.street_number != null) {
        street = addressData.street_number;
      }
      this.form.address =
        street + " " + addressData.route;
      this.address = addressData;
    },
      getCityIdByName(city_name) {
        this.addressLoader = true;
        getCityByName(city_name).then((response) => {
        
            const city_id = response.data.data.city_id;
            const stateId = response.data.data.state_id;
            this.addressLoader = true;
            getCityListByStateId(stateId).then((response) => {
              this.city_list = response.data.data;
              this.form.city_id = city_id;
              this.city_loading = false;
              this.addressLoader = false;
            });
          
          this.addressLoader = false;
        });
      },
      getStateByStatePrefix(state_prefix) {
        this.addressLoader = true;
        getStateByPrefix(state_prefix).then((response) => {
          this.form.state_id = response.data.data.state_id;
        
          this.addressLoader = false;
        });
      },
      onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files;

        if (files.length <= 0) {
          return;
        }

        this.form.website_logo = files[0];
        this.img_preview = URL.createObjectURL(files[0]);
      },
       checkZip(value) {
            return (/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/).test(value);
         },
          validPostal(postalcode){   
             let postal_code = postalcode.toUpperCase(); 
             if (this.checkZip(postal_code)) {
                  this.form.post_code = postal_code.replace(/\W/g,'').replace(/(...)/,'$1 ');
               } else {
                  const h = this.$createElement;
                this.$notify({
                    message: h('i', {
                        style: 'color: red'
                    }, 'Invalid Postal Code'),
                    offset: 200,
                    type: 'warning'
                });                       
                    this.form.post_code = '';
               }
          },
      dataURItoBlob(dataURI) {
        var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var binary = atob(dataURI.split(',')[1]);
        var array = [];
        for (var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], {type: mime});
      },
      getCityListByStateId(){
        this.loading = true
        getCityListByStateId(this.form.state_id).then(response => {
          this.city_list = response.data.data
          this.loading = false
        })
      },
      getCityList(){
          this.loading = true
          getCityList().then(response => {
              this.city_list = response.data.data
              this.loading = false
          })
      },
      getStateList(){
          this.loading = true
          getStateList().then(response => {
              this.state_list = response.data.data
              this.loading = false
          })
      },
    },
    created(){
      let websiteId = this.$route.params.websiteId;

      this.logo_btn_text = 'Add Image'
      if(parseInt(websiteId) > 0){
        
        this.submit_btn_text = 'Update Store'
        this.title_text = 'Update Store'
        this.response_msg = 'Store updated successfully.'

       getWebsiteDetails(websiteId).then(response => {
          if(response.data.data.id){
            this.api_url = 'website/update/' + response.data.data.id
          }
          this.form = new Form(response.data.data)
          if(response.data.data.website_logo){
            this.logo_btn_text = 'Update'
            this.img_preview = process.env.VUE_APP_BASE_IMAGE_URL + response.data.data.website_logo
            this.image_btn = 1;
          }
        });
      }
      this.getStateList();
      this.getCityList();
    }
}
</script>
